import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

// import '../assets/scss/index.scss';

// markup

function Home() {
  return (
    <section className="hero is-fullheight-with-navbar">
      <Seo title="Shishir Singh Home" />
      <div className="hero-body has-text-centered is-justify-content-center spacer animated-shape">
        <div className="">
          <p className="title is-1">Shishir Singh</p>
          <p className="title is-size-4 is-size-3-desktop">
            I develop websites and apps <br /> that help businesses grow.
          </p>
          <p className="subtitle is-6">
            Full stack developer for Web and Mobile
          </p>
        </div>
      </div>
    </section>
  )
}

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Home />
      </Layout>
    </>
  )
}

export default IndexPage
